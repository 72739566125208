import { giveServiceTo } from './searchServicesApi'
import { ElMessage } from "element-plus";
export const searchs = (data) => {
	data.loading = true
	let param = {
			keyWords: data.searchFor.searchValue,
			pageNo: data.currentPage,
			pageSize: data.pageSize
	};
	giveServiceTo(param).then((res) => {
		if (res.status == 200) {
			ElMessage.success('获取成功！')
			data.serviceList = res.data.data.items
			data.total = res.data.data.total
			data.loading = false
		} else {
			ElMessage.error('获取失败！')
		}
	})
}